<template>
  <div>
    <v-row justify="center">
      <!-- 대리점인경우 -->
      <v-col
        v-if="loggedInUser.dealerKind && loggedInUser.dealerKind == 34"
        cols="12"
        xl="6"
        sm="8"
        md="8"
      >
        <v-row>
          <v-col cols="12">

            <crypto-currency-card
              bg-card-color="primary"
              heading="가맹점"
              main-icon-text-color="primary--text text--lighten-4"
              main-icon-name="mdi-lighthouse-on"
              :sub-heading="String(count1)"
              style="cursor:pointer;"
            />
          </v-col>

        </v-row>

        <v-row>
          <v-col
            cols="12"
          >
            <base-card>
              <v-card-text>

                <v-row dense>
                  <v-col id="search_date">
                    <v-menu
                      ref="menuTwo"
                      v-model="menuTwo"
                      class="text-caption text-lg-subtitle-1"
                      max-width="290"
                      :return-value.sync="dates"
                      transition="scale-transition"
                      offset-y
                      :close-on-content-click="false"
                    >
                      <template v-slot:activator="{on, attrs}">
                        <v-combobox
                          v-model="dateRangeText"
                          prepend-icon="mdi-calendar"
                          readonly
                          dense
                          v-bind="attrs"
                          class="text-caption text-lg-subtitle-1"
                          hide-details
                          hide-no-data
                          outlined
                          v-on="on"
                        />
                      </template>
                      <v-date-picker
                        v-model="dates"
                        range
                        no-title
                        scrollable
                        locale="kr"

                      >
                        <v-spacer/>
                        <v-btn
                          text
                          color="primary"
                          @click="menuTwo = false"
                        >
                          취소
                        </v-btn>
                        <!--
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menuTwo.save(dates)"
                        >
                          확인
                        </v-btn>
                        -->

                        <v-btn
                          text
                          color="primary"
                          @click="datesSave(dates)"
                        >
                          확인
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-card-text>

              <search-add
                :search-list="searchList"
                @searchFormEvent="searchFormEvent"
              />
            </base-card>
          </v-col>
        </v-row>


        <v-row>
          <v-col cols="12">
            <div class="d-flex pr-1 justify-space-between">
              <div class="d-flex align-center">
                <v-icon
                  small
                  color="primary"
                  class="mr-3"
                >
                  mdi-circle
                </v-icon>
                <h5 class="mb-0 mr-2 font-weight-bold">
                  가맹점
                </h5>
              </div>
            </div>
          </v-col>
          <v-col>
            <base-card :loading="loading">
              <v-card-text>
                <v-simple-table fixed-header>
                  <thead>
                  <tr>
                    <th
                      class="text-left"
                      style="max-width:50px"
                    >
                      순번
                    </th>
                    <th class="text-center">
                      <!--                      표 제목 selectdMenu 값으로 대체-->
                      <!--                      {{ selectedMenu }}-->
                      상호
                    </th>
                    <th
                      class="text-right"
                      style="max-width:50px;"
                    >
                      대표자
                    </th>
                  </tr>
                  </thead>
                  <tbody v-if="merchantManagementList.length > 0">
                  <tr>
                    <td colspan="3">
                      <v-expansion-panels
                        flat
                        class="mx-0"
                        v-for="(item, index) in merchantManagementList"
                        :key="index"
                      >
                        <v-expansion-panel>
                          <v-expansion-panel-header
                            hide-actions
                            class="text-caption mx-0 "
                          >
                            <v-row no-gutters>
                              <v-col cols="2">
                                {{ item.no }}
                              </v-col>
                              <v-col
                                cols="8"
                                class="text-center"
                              >
                                {{ item.companyName }}
                              </v-col>
                              <v-col
                                cols="2"
                                class="text-right"
                              >
                                {{ item.mberName }}
                              </v-col>
                            </v-row>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <base-card class="mb-3">
                              <v-card-text>
                                <div class="mt-3">
                                  <div
                                    class="d-flex justify-space-between"
                                  >
                                    <p
                                      class="text--disabled mb-1 text-12 mr-2"
                                      style="min-width: 100px"
                                    >
                                      아이디
                                    </p>
                                    <p
                                      class="text-success mb-1 text-12"
                                    >
                                      {{ item.usid }}
                                    </p>
                                  </div>
                                  <div
                                    class="d-flex justify-space-between"
                                  >
                                    <p
                                      class="text--disabled mb-1 text-12 mr-2"
                                      style="min-width: 100px"
                                    >
                                      등록일
                                    </p>
                                    <p
                                      class="text-success mb-1 text-12"
                                    >
                                      {{ item.creatDt }}
                                    </p>
                                  </div>
                                  <div
                                    class="d-flex justify-space-between"
                                  >
                                    <p
                                      class="text--disabled mb-1 text-12 mr-2"
                                      style="min-width: 100px"
                                    >
                                      정산코드
                                    </p>
                                    <p
                                      class="text-success mb-1 text-12"
                                    >
                                      {{ item.calculateType }}
                                    </p>
                                  </div>
                                  <div
                                    class="d-flex justify-space-between"
                                  >
                                    <p
                                      class="text--disabled mb-1 text-12 mr-2"
                                      style="min-width: 100px"
                                    >
                                      수수료율
                                    </p>
                                    <p
                                      class="text-success mb-1 text-12"
                                    >
                                      {{ item.feeRate }}
                                    </p>
                                  </div>
                                  <div
                                    class="d-flex justify-space-between"
                                  >
                                    <p
                                      class="text--disabled mb-1 text-12 mr-2"
                                      style="min-width: 100px"
                                    >
                                      주소
                                    </p>
                                    <p
                                      class="text-success mb-1 text-12 text-sm-caption"
                                    >
                                      {{ item.adres }}
                                    </p>
                                  </div>
                                  <div
                                    class="d-flex justify-space-between"
                                  >
                                    <p
                                      class="text--disabled mb-1 text-12 mr-2"
                                      style="min-width: 100px"
                                    >
                                      연락처
                                    </p>
                                    <p
                                      class="text-success mb-1 text-12 "
                                    >
                                      {{ item.mberMobile }}
                                    </p>
                                  </div>
                                  <div
                                    class="d-flex justify-space-between"
                                  >
                                    <p
                                      class="text--disabled mb-1 text-12 mr-2"
                                      style="min-width: 100px"
                                    >
                                      이메일
                                    </p>
                                    <p
                                      class="text-success mb-1 text-12 "
                                    >
                                      {{ item.email }}
                                    </p>
                                  </div>
                                  <div
                                    class="d-flex justify-space-between"
                                  >
                                    <p
                                      class="text--disabled mb-1 text-12 mr-2"
                                      style="min-width: 100px"
                                    >
                                      계좌정보
                                    </p>
                                    <p
                                      class="text-success mb-1 text-12"
                                    >
                                      {{ item.bankName }} {{ item.accountNo }} {{ item.depositor }}
                                    </p>

                                  </div>
                                  <div
                                    class="d-flex justify-space-between"
                                  >
                                    <p
                                      class="text--disabled mb-1 text-12 mr-2"
                                      style="min-width: 100px"
                                    >
                                      머니택스
                                    </p>
                                    <p
                                      class="text-success mb-1 text-12"
                                    >

                                      <template v-if="item.mtaxRegYn == 'Y'">
                                        가입
                                      </template>
                                      <template v-else>
                                        미가입
                                      </template>
                                    </p>
                                  </div>
                                </div>
                              </v-card-text>
                            </base-card>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </td>
                  </tr>
                  <tr>
                    <td
                      colspan="4"
                      class="text-right"
                      v-if="merchantManagementList.length >0"
                    >
                      {{ merchantManagementCount }} 개
                    </td>
                  </tr>
                  </tbody>
                  <tfoot v-if="merchantManagementList.length==0">
                  <tr>
                    <td colspan="4" class="text-center">조회된 정보가 없습니다.</td>
                  </tr>
                  </tfoot>
                </v-simple-table>
              </v-card-text>
            </base-card>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col
            cols="12"
            xl="12"
          >
            <v-container class="max-width">
              <v-pagination
                v-model="current"
                class="my-4"
                :length="pageCount"
                :total-visible="10"
                @next="pageHandler()"
                @previous="pageHandler()"
                @input="pageHandler()"
              />
            </v-container>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        v-if="loggedInUser.dealerKind && loggedInUser.dealerKind == 33"
        cols="12"
        xl="6"
        sm="8"
        md="8"
      >
        <v-row>
          <v-col cols="12" style="display: none;">
            <div class="d-flex">
              <v-icon
                small
                color="primary"
                class="mr-3"
                chips
              >
                mdi-circle
              </v-icon>
              <v-overflow-btn
                dense
                class="mx-auto"
                :items="dropdown_edit"
                item-value="text"
                v-model="selectedMenu"
              />
            </div>
          </v-col>
          <v-col
            cols="6"
            @click="changeEvent('a')"
          >
            <crypto-currency-card
              bg-card-color="primary"
              heading="가맹점"
              main-icon-text-color="primary--text text--lighten-4"
              main-icon-name="mdi-lighthouse-on"
              :sub-heading="String(count1)"
              style="cursor:pointer;"
            />
          </v-col>
          <v-col
            cols="6"
            @click="changeEvent('b')"
          >
            <crypto-currency-card
              bg-card-color="red"
              heading="대리점가맹점"
              main-icon-text-color="warning--text text--lighten-4"
              main-icon-name="mdi-handshake-outline"
              :sub-heading="String(count2)"
              style="cursor:pointer;"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
          >
            <base-card>
              <v-card-text>

                <v-row dense>
                  <v-col id="search_date">
                    <v-menu
                      ref="menuTwo"
                      v-model="menuTwo"
                      class="text-caption text-lg-subtitle-1"
                      max-width="290"
                      :return-value.sync="dates"
                      transition="scale-transition"
                      offset-y
                      :close-on-content-click="false"
                    >
                      <template v-slot:activator="{on, attrs}">
                        <v-combobox
                          v-model="dateRangeText"
                          prepend-icon="mdi-calendar"
                          readonly
                          dense
                          v-bind="attrs"
                          class="text-caption text-lg-subtitle-1"
                          hide-details
                          hide-no-data
                          outlined
                          v-on="on"
                        />
                      </template>
                      <v-date-picker
                        v-model="dates"
                        range
                        no-title
                        scrollable
                        locale="kr"

                      >
                        <v-spacer/>
                        <v-btn
                          text
                          color="primary"
                          @click="menuTwo = false"
                        >
                          취소
                        </v-btn>
                        <!--
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menuTwo.save(dates)"
                        >
                          확인
                        </v-btn>
                        -->

                        <v-btn
                          text
                          color="primary"
                          @click="datesSave(dates)"
                        >
                          확인
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-card-text>

              <search-add
                :search-list="searchList"
                @searchFormEvent="searchFormEvent"
              />
            </base-card>
          </v-col>
        </v-row>

        <v-row>

          <v-col>
            <div class="d-flex pr-1 justify-space-between pb-0">
              <div class="d-flex align-center">
                <v-icon
                  small
                  color="primary"
                  class="mr-3"
                >
                  mdi-circle
                </v-icon>
                <h5 class="mb-0 mr-0 font-weight-bold">

                  {{ selectedMenu }}

                </h5>
              </div>
            </div>


            <base-card>

              <v-card-text>

                <v-simple-table fixed-header>
                  <thead>
                  <tr>
                    <th
                      class="text-left"
                      style="max-width:50px"
                    >
                      순번
                    </th>

                    <th
                      class="text-center"
                      v-if="this.selectedMenu === '대리점가맹점'"
                    >
                      소속대리점
                    </th>

                    <th class="text-center">
                      <!--                      표 제목 selectdMenu 값으로 대체-->
                      <!--                      {{ selectedMenu }}-->
                      상호
                    </th>
                    <th
                      class="text-right"
                      style="max-width:50px;"
                    >
                      대표자
                    </th>
                  </tr>
                  </thead>
                  <tbody v-if="merchantManagementList.length > 0">
                  <tr>
                    <td colspan="4">
                      <v-expansion-panels
                        flat
                        class="mx-0"
                        v-for="(item, index) in merchantManagementList"
                        :key="index"
                      >
                        <v-expansion-panel>
                          <v-expansion-panel-header
                            hide-actions
                            class="text-caption mx-0 "
                          >
                            <v-row
                              no-gutters
                              v-if="selectedMenu=== '가맹점'"
                            >
                              <v-col cols="2">
                                {{ item.no }}
                              </v-col>
                              <v-col
                                cols="8"
                                class="text-center"
                              >
                                {{ item.companyName }}
                              </v-col>
                              <v-col
                                cols="2"
                                class="text-right"
                              >
                                {{ item.mberName }}
                              </v-col>
                            </v-row>

                            <v-row
                              no-gutters
                              v-if="selectedMenu=== '대리점가맹점'"
                            >
                              <v-col cols="2">
                                {{ item.no }}
                              </v-col>

                              <v-col
                                cols="4"
                                class="text-center"
                              >
                                {{ item.bossName }}
                              </v-col>

                              <v-col
                                cols="4"
                                class="text-center"
                              >
                                {{ item.companyName }}
                              </v-col>
                              <v-col
                                cols="2"
                                class="text-right"
                              >
                                {{ item.mberName }}
                              </v-col>
                            </v-row>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <base-card class="mb-3">
                              <v-card-text>
                                <div class="mt-3">
                                  <div
                                    class="d-flex justify-space-between"
                                  >
                                    <p
                                      class="text--disabled mb-1 text-12 mr-2"
                                      style="min-width: 100px"
                                    >
                                      아이디
                                    </p>
                                    <p
                                      class="text-success mb-1 text-12"
                                    >
                                      {{ item.usid }}
                                    </p>
                                  </div>
                                  <div
                                    class="d-flex justify-space-between"
                                  >
                                    <p
                                      class="text--disabled mb-1 text-12 mr-2"
                                      style="min-width: 100px"
                                    >
                                      등록일
                                    </p>
                                    <p
                                      class="text-success mb-1 text-12"
                                    >
                                      {{ item.creatDt }}
                                    </p>
                                  </div>
                                  <div
                                    class="d-flex justify-space-between"
                                  >
                                    <!--
                                    <p
                                      class="text--disabled mb-1 text-12 mr-2"
                                      style="min-width: 100px"
                                    >
                                      모집대리점
                                    </p>
                                    <p
                                      class="text-success mb-1 text-12"
                                    >
                                      {{ item.bossName }}
                                    </p>
                                    -->
                                    <p
                                      class="text--disabled mb-1 text-12 mr-2"
                                      style="min-width: 100px"
                                    >
                                      정산코드
                                    </p>
                                    <p
                                      class="text-success mb-1 text-12"
                                    >
                                      {{ item.calculateType }}
                                    </p>
                                  </div>
                                  <div
                                    class="d-flex justify-space-between"
                                  >
                                    <p
                                      class="text--disabled mb-1 text-12 mr-2"
                                      style="min-width: 100px"
                                    >
                                      수수료율
                                    </p>
                                    <p
                                      class="text-success mb-1 text-12"
                                    >
                                      {{ item.feeRate }}
                                    </p>
                                  </div>
                                  <div
                                    class="d-flex justify-space-between"
                                  >
                                    <p
                                      class="text--disabled mb-1 text-12 mr-2"
                                      style="min-width: 100px"
                                    >
                                      주소
                                    </p>
                                    <p
                                      class="text-success mb-1 text-12 text-sm-caption"
                                    >
                                      {{ item.adres }}
                                    </p>
                                  </div>
                                  <div
                                    class="d-flex justify-space-between"
                                  >
                                    <p
                                      class="text--disabled mb-1 text-12 mr-2"
                                      style="min-width: 100px"
                                    >
                                      연락처
                                    </p>
                                    <p
                                      class="text-success mb-1 text-12 "
                                    >
                                      {{ item.mberMobile }}
                                    </p>
                                  </div>
                                  <div
                                    class="d-flex justify-space-between"
                                  >
                                    <p
                                      class="text--disabled mb-1 text-12 mr-2"
                                      style="min-width: 100px"
                                    >
                                      이메일
                                    </p>
                                    <p
                                      class="text-success mb-1 text-12 "
                                    >
                                      {{ item.email }}
                                    </p>
                                  </div>
                                  <div
                                    class="d-flex justify-space-between"
                                  >
                                    <p
                                      class="text--disabled mb-1 text-12 mr-2"
                                      style="min-width: 100px"
                                    >
                                      계좌
                                    </p>
                                    <p
                                      class="text-success mb-1 text-12"
                                    >
                                      {{ item.bankName }} {{ item.accountNo }} {{ item.depositor }}
                                    </p>
                                  </div>

                                  <div
                                    class="d-flex justify-space-between"
                                  >
                                    <p
                                      class="text--disabled mb-1 text-12 mr-2"
                                      style="min-width: 100px"
                                    >
                                      머니택스 가입여부
                                    </p>
                                    <p
                                      class="text-success mb-1 text-12"
                                    >
                                      <template v-if="item.mtaxRegYn == 'Y'">
                                        가입
                                      </template>
                                      <template v-else>
                                        미가입
                                      </template>
                                    </p>
                                  </div>
                                </div>
                              </v-card-text>
                            </base-card>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </td>
                  </tr>
                  <tr>
                    <td
                      colspan="4"
                      class="text-right"
                      v-if="merchantManagementList.length >0"
                    >
                      {{ merchantManagementCount }} 개
                    </td>
                  </tr>
                  </tbody>
                  <tfoot v-if="merchantManagementList.length == 0">
                  <tr>
                    <td colspan="4" class="text-center">
                      조회된 정보가 없습니다.
                    </td>
                  </tr>
                  </tfoot>
                </v-simple-table>
              </v-card-text>
            </base-card>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col
            cols="12"
            xl="12"
          >
            <v-pagination
              v-model="current"
              class="my-4"
              :length="pageCount"
              :total-visible="10"
              @next="pageHandler()"
              @previous="pageHandler()"
              @input="pageHandler()"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import SearchAdd from '@/components/base/SearchAdd'
  import CryptoCurrencyCard from '@/components/card/CryptoCurrencyCard'
  import { mapActions, mapGetters, mapMutations } from 'vuex'
  import { dataType } from '@/filter/filter'
  import axios from 'axios'
  import { baseUrl } from '@/store/CONSTANT'

  export default {
    name: 'Franchise',
    components: {
      SearchAdd,
      CryptoCurrencyCard,
    },
    created () {

      const todayDate = new Date()
      const todayYear = todayDate.getUTCFullYear()
      const todayMonth = Number(todayDate.getUTCMonth()) + 1 < 10 ? '0' + (Number(todayDate.getUTCMonth()) + 1) : Number(todayDate.getUTCMonth()) + 1
      const todayDay = Number(todayDate.getUTCDate()) < 10 ? '0' + todayDate.getUTCDate() : todayDate.getUTCDate()
      const today = todayYear + '-' + todayMonth + '-' + todayDay
      this.dates = ['2010-01-01', today]
      this.initData()
    },

    mounted () {
      const search_date_document = document.getElementById("search_date");
      search_date_document.addEventListener("click", (e) => {
        this.menuTwo = true;
      })


      this.payLoad.startNo = 0
      this.payLoad.endNo = 10
      this.payLoad.startDt = this.dates[0]
      this.payLoad.endDt = this.dates[1]
      this.payLoad.userId = this.loggedInUser.dealerId
      this.payLoad.dealerKind = this.loggedInUser.dealerKind
      this.setSelectedMenu('가맹점관리')

      this.payLoad.startDt = this.dates[0]
      this.payLoad.endDt = this.dates[1]
      // this.fetchMerchantManagementList(this.payLoad).then(_ => {
      //   this.pageCount = Math.ceil(this.merchantManagementCount / this.pageSize)
      // })

      if (this.loggedInUser.dealerKind === 33) {
        if (this.selectedMenu === '가맹점') this.payLoad.type = 'a'
        if (this.selectedMenu === '대리점가맹점') this.payLoad.type = 'b'
      }

      this.countAll()
      this.fetchMerchantManagementList(this.payLoad).then(_ => {
        this.pageCount = Math.ceil(this.merchantManagementCount / this.pageSize)
      })

    },
    data: () => ({
      selectedMenu: '가맹점',
      current: 1,
      pageSize: 10,
      pageCount: 1,
      dates: [],
      menuTwo: false,
      payLoad: {
        userId: '',
        dealerId: '',
        dealerName: '',
        dealerMemberName: '',
        startDt: '',
        endDt: '',
        dealerKind: '',
        startNo: 1,
        endNo: 10,
        mtaxRegYn: '',
      },
      loading: false,
      count1: 0,
      count2: 0,
      searchList: [
        {
          text: '전체',
          value: 'all',
        },
        {
          text: '가맹점명',
          value: 'dealerName',
        },
        {
          text: '아이디',
          value: 'dealerId',
        },
        {
          text: '대표자명',
          value: 'dealerMemberName',
        },
        {
          text: "머니택스",
          value: "mtaxRegYn"
        }
      ],
      searchList2: [
        {
          text: '전체',
          value: 'all',
        },
        {
          text: '모집대리점',
          value: 'soSokName',
        },
        {
          text: '가맹점명',
          value: 'dealerName',
        },
        {
          text: '아이디',
          value: 'dealerId',
        },
        {
          text: '대표자명',
          value: 'dealerMemberName',
        },
      ],
      dropdown_edit: [
        {
          text: '가맹점',
          type: 'A',
        },
        {
          text: '대리점가맹점',
          type: 'B',
        },
      ],
    }),
    computed: {
      ...mapGetters(['loggedInUser', 'merchantManagementList', 'merchantManagementCount']),
      dateRangeText () {
        return this.dates.join(' ~ ')
      },
    },
    methods: {
      ...mapActions(['fetchMerchantManagementList', 'fetchMerchantManagementCount']),
      ...mapMutations(['setMerchantManagementCount', 'setMerchantManagementList']),
      ...mapMutations(['setSelectedMenu']),
      initData () {
        if (this.dates.length === 0) {
          const today = dataType()
          // let preDay = new Date()
          // preDay.setDate(1)
          // //preDay.setMonth(preDay.getMonth() - 1)
          // preDay = dataType(preDay)
          this.dates = [today, today]
        }
        this.current = 1
        this.payLoad = {
          dealerKind: this.loggedInUser.dealerKind,
          userId: this.loggedInUser.dealerId,
          dealerId: '',
          soSokName: '',
          dealerName: '',
          dealerMemberName: '',
          startDt: this.dates[0],
          endDt: this.dates[1],
          mtaxRegYn: '',
          startNo: 1, // 시작 페이지
          endNo: this.pageSize, // 종료 페이지 번호
          type: '',
        }

        // vuex 초기화
        this.setMerchantManagementList([])
        this.setMerchantManagementCount = 0
      },
      searchFormEvent (arrObj) {
        if (!!arrObj && arrObj.length > 0) {
          for (const el of arrObj) {
            if (el.key === 'all') {
              this.payLoad.dealerName = null
              this.payLoad.dealerMemberName = null
              this.payLoad.dealerId = null
              this.payLoad.soSokName = null
              this.payLoad.mtaxRegYn = ''
            }
            if (el.key === 'dealerName') {
              this.payLoad.dealerName = el.value
              this.payLoad.dealerMemberName = null
              this.payLoad.dealerId = null
              this.payLoad.soSokName = null
              this.payLoad.mtaxRegYn = ''

            }
            if (el.key === 'dealerId') {
              this.payLoad.dealerId = el.value
              this.payLoad.dealerMemberName = null
              this.payLoad.dealerName = null
              this.payLoad.soSokName = null
              this.payLoad.mtaxRegYn = ''

            }
            if (el.key === 'dealerMemberName') {
              this.payLoad.dealerMemberName = el.value
              this.payLoad.dealerId = null
              this.payLoad.dealerName = null
              this.payLoad.soSokName = null
              this.payLoad.mtaxRegYn = ''

            }
            if (el.key === 'soSokName') {
              this.payLoad.soSokName = el.value
              this.payLoad.dealerMemberName = null
              this.payLoad.dealerId = null
              this.payLoad.dealerName = null
              this.payLoad.mtaxRegYn = ''

            }

            if (el.key === 'mtaxRegYn') {
              this.payLoad.soSokName = null
              this.payLoad.dealerMemberName = null
              this.payLoad.dealerId = null
              this.payLoad.dealerName = null
              this.payLoad.mtaxRegYn = el.value
            }

          }
        }
        if (this.loggedInUser.dealerKind === 33) {
          if (this.selectedMenu === '가맹점') this.payLoad.type = 'a'
          if (this.selectedMenu === '대리점가맹점') this.payLoad.type = 'b'
        }
        this.loading = true
        this.payLoad.startDt = this.dates[0]
        this.payLoad.endDt = this.dates[1]
        this.fetchMerchantManagementList(this.payLoad).then(_ => {
          this.pageCount = Math.ceil(this.merchantManagementCount / this.pageSize)
          this.loading = false
        })
      },

      agencyTabClick (tabIndex) {
        if (tabIndex == 1) {
          this.payLoad.type = 'a'
        }

        if (tabIndex == 2) {
          this.payLoad.type = 'b'
        }
        if (this.loggedInUser.dealerKind === 33) {
          if (this.selectedMenu === '가맹점') this.payLoad.type = 'a'
          if (this.selectedMenu === '대리점가맹점') this.payLoad.type = 'b'
        }
      },
      pageHandler () {
        this.payLoad.startNo = (this.current - 1) * this.pageSize + 1
        this.payLoad.endNo = this.pageSize * this.current
        this.searchFormEvent()
      },

      async countAll () {
        const sendData1 = {
          dealerKind: this.loggedInUser.dealerKind,
          userId: this.loggedInUser.dealerId,
          dealerId: '',
          dealerName: '',
          dealerMemberName: '',
          startDt: this.dates[0],
          endDt: this.dates[1],
          startNo: 1, // 시작 페이지
          mtaxRegYn: this.payLoad.mtaxRegYn,
          endNo: this.pageSize, // 종료 페이지 번호
          type: 'a',
        }

        const sendData2 = {
          dealerKind: this.loggedInUser.dealerKind,
          userId: this.loggedInUser.dealerId,
          dealerId: '',
          dealerName: '',
          dealerMemberName: '',
          startDt: this.dates[0],
          endDt: this.dates[1],
          startNo: 1, // 시작 페이지
          mtaxRegYn: this.payLoad.mtaxRegYn,
          endNo: this.pageSize, // 종료 페이지 번호
          type: 'b',
        }

        const [count1, count2] = await Promise.all([
          this.fetchMerchantManagementCount(sendData1)
          , this.fetchMerchantManagementCount(sendData2),
        ])
        this.count1 = count1
        this.count2 = count2

      },

      changeEvent (type) {
        if (type == 'a') {
          this.selectedMenu = '가맹점'
        }

        if (type == 'b') {
          this.selectedMenu = '대리점가맹점'
        }

        this.payLoad.soSokName = null
        this.payLoad.dealerMemberName = null
        this.payLoad.dealerId = null
        this.payLoad.dealerName = null

        this.searchFormEvent()
      },
      datesSave (date) {
        date = date.sort()
        this.$refs.menuTwo.save(date)
      },

    },

  }
</script>

<style>
.franchise_total {
  padding: 20px;
}

.v-data-table td {
  padding: 0px;
}
</style>
